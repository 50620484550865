/* eslint-disable tailwindcss/no-contradicting-classname */
/* eslint-disable tailwindcss/classnames-order */
import * as React from "react";
import { Document } from "@contentful/rich-text-types";
import { IContentfulImage } from "@src/lib/types";
import {
  ContentfulImage,
  ProductContainer,
  ProductImageWithTextBlock,
  RichText,
  SmartLink
} from "@src/components";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import { Pagination } from "swiper";
import { TrendReportOneColumnText } from "../TrendReportOneColumnText";
import { TrendReportImageWithTextBlock } from "../TrendReportImageWithTextBlock";
import { ContentfulImageUrlTransforms } from "@src/lib/utils";

export type TrendReportTabbedContentProps = {
  id?: string | null;
  heading?: string | null;
  eyebrow?: string | null;
  html?: string | null;
  ctaStyle?: string | null;
  items?: TrendReportTabbedContentImageWithTextProps[];
};

export type TrendReportTabbedContentImageWithTextProps = {
  cta?: string | null;
  slide?: {
    eyebrow?: string | null;
    heading?: string | null;
    description?: string | null;
    image?: IContentfulImage | null;
    imagePosition?: string | null;
    imageMobilePosition?: string | null;
    text?:
      | {
          json?: Document;
        }
      | null
      | undefined;
    ctaTitle?: string | null;
    ctaUrl?: string | null;
  } | null;
};

function ImageWithText({
  slide
}: Readonly<TrendReportTabbedContentImageWithTextProps>) {
  const orientation =
    slide?.imagePosition == "Left" ? "imageonleft" : "imageonright";
  const mobileOrientation =
    slide?.imageMobilePosition == "Bottom" ? "imageonbottom" : "imageontop";

  const fullWidthImageDimensions = {
    width: slide?.image?.width || 1648,
    height: slide?.image?.height || 1470
  };

  const fullWidthImageTransforms: ContentfulImageUrlTransforms = {
    width: fullWidthImageDimensions.width,
    height: fullWidthImageDimensions.height
  };

  const imageColClassNames: { [key: string]: string } = {
    imageonleft: "order-1 md:mx-0 md:col-span-2 md:col-start-1 md:col-end-2",
    imageonright: "order-2 md:mx-0 md:col-span-2 md:col-start-2 md:col-end-3"
  };

  const textColClassNames: { [key: string]: string } = {
    imageonleft:
      "py-12 w-full md:col-span-2 md:col-start-2 md:col-end-3 md:max-w-[696px] md:justify-self-start md:px-5 lg:px-8 2xl:px-24",
    imageonright:
      "py-12 w-full md:col-span-2 md:col-start-1 md:col-end-2 md:max-w-[696px] md:justify-self-end md:px-5 lg:px-8 2xl:px-24",
    imageonbottom: "order-1",
    imageontop: "order-2"
  };

  const imageColClassName = imageColClassNames[orientation];
  const textColClassName = textColClassNames[orientation];
  const mobileTextColClassName = textColClassNames[mobileOrientation];

  return (
    <div
      className={`grid content-center items-center md:grid-flow-col-dense md:grid-cols-2 `}
    >
      {slide?.image && (
        <div className={`col-span-full ${imageColClassName}`}>
          <figure className={`relative w-full`}>
            <ContentfulImage
              image={slide.image}
              transforms={fullWidthImageTransforms}
            />
          </figure>
        </div>
      )}
      <div
        className={`col-span-full flex flex-col justify-center ${mobileTextColClassName} ${textColClassName}`}
      >
        {slide?.eyebrow && (
          <p className={`type-pr-theta md:type-pr-eta text-pr-dark-gray-400`}>
            {slide.eyebrow}
          </p>
        )}

        <div
          className={`space-y-2 lg:space-y-4 ${
            slide?.eyebrow ? "mt-1.5 lg:mt-3" : ""
          }`}
        >
          <h2 className="type-pr-delta lg:text-[2.75rem]">{slide?.heading}</h2>
          {slide?.text && (
            <div className="prose prose-pr prose-pr-gray-bullets lg:prose-pr-lg lg:prose-pr-gray-bullets">
              <RichText text={slide.text.json as Document} />
            </div>
          )}
          {slide?.ctaTitle && (
            <SmartLink
              href={slide.ctaUrl ?? ""}
              className={`
                  pr-button
                  pr-button-lg
                  pr-button
                  ease
                  bg-ada-salmon-500
                  !bg-none
                  text-white
                  hover:bg-ada-green-400
                  hover:text-black
                  lg:mx-0
                `}
            >
              {slide.ctaTitle}
            </SmartLink>
          )}
        </div>
      </div>
    </div>
  );
}

export function TrendReportTabbedContent({
  id = "",
  heading,
  eyebrow,
  html,
  ctaStyle,
  items
}: Readonly<TrendReportTabbedContentProps>) {
  const paginationClassName = id ? `pagination-${id}` : "pagination";

  const defaultCtaStyle: string = "teal";

  const ctaClassNames: { [key: string]: string } = {
    red: "swiper-pagination-bullet-trend-red",
    green: "swiper-pagination-bullet-trend-green",
    yellow: "swiper-pagination-bullet-trend-yellow",
    teal: "swiper-pagination-bullet-trend-teal"
  };

  const ctaActiveClassNames: { [key: string]: string } = {
    red: "swiper-pagination-bullet-active-trend-red",
    green: "swiper-pagination-bullet-active-trend-green",
    yellow: "swiper-pagination-bullet-active-trend-yellow",
    teal: "swiper-pagination-bullet-active-trend-teal"
  };

  const ctaClassName = ctaClassNames[ctaStyle ? ctaStyle : defaultCtaStyle];
  const ctaActiveClassName =
    ctaActiveClassNames[ctaStyle ? ctaStyle : defaultCtaStyle];

  return (
    <ProductContainer>
      <div className="w-full">
        <TrendReportOneColumnText
          heading={heading}
          eyebrow={eyebrow}
          html={html}
        />
        <div
          className={`mt-8 flex flex-row flex-wrap items-center justify-center gap-4 pb-5 md:flex-row lg:gap-7 lg:pb-8 ${paginationClassName}`}
        ></div>
        {items && (
          <Swiper
            pagination={{
              el: `.${paginationClassName}`,
              clickable: true,
              bulletActiveClass: ctaActiveClassName,
              bulletClass: ctaClassName,
              renderBullet: function (index, className) {
                return `<span class="${className}"> ${
                  items[index]?.cta as string
                } </span>`;
              }
            }}
            modules={[Pagination]}
            className="mySwiper"
          >
            {items.map((item, index) => (
              <SwiperSlide key={index}>
                <ImageWithText slide={item.slide} />
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </div>
    </ProductContainer>
  );
}

export default TrendReportTabbedContent;
